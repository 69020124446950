import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get("tabelatuss/listar?" + param2query(params), cancelToken);
};

export const getTabelaDominio = (id) => {
  return api.get("tabelatuss/" + id);
};

export const criarTabelaDominio = (model) => {
  return api.post("tabelatuss", model);
};

export const atualizarTabelaDominio = (model) => {
  return api.put("tabelatuss", model);
};


export default { getAll };
